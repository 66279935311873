:root {
  --dv-body-text-color: #{$dark};
  --dv-body-bg-color: #{$white};

  --dv-btn-primary-color: #{$white};
  --dv-btn-primary-bg: #{$green};
  --dv-btn-primary-bg-hover: #{$green-light};

  --dv-btn-secondary-color: #{$white};
  --dv-btn-secondary-bg: #{$dark};

  --dv-form-color: #{$dark};
  --dv-form-bg: #{$white};
  --dv-form-error-color: #{$red};
  --dv-form-error-border: #{$red};
  --dv-form-border-focus-color: #{$green};

  --primary-orange: #FAA31E;
  --secondary-orange: #FEEED6;

  --primary-red: #E05252;
  --secondary-red: #F8D9D9;

  --primary-green: #37B358;
  --secondary-green: #DBF1E1;

  --primary-blue: #2D6DB7;
  --secondary-blue: #F1F6FB;

  --primary-gray: #5F6A70;
  --secondary-gray: #DEDEDE;

  --hover-color: #F0F0F0;

  $color-white: var(--color-white, #fff) !default;
  $color-black: var(--color-black, #000) !default;

  --dv-body-font-size: #{$fs-base};
  --dv-body-font-weight: #{$fw-normal};

  --dv-h1-font-size: #{$h-1};
  --dv-h2-font-size: #{$h-2};
  --dv-h3-font-size: #{$h-3};
  --dv-h4-font-size: #{$h-4};
  --dv-h5-font-size: #{$h-5};
  --dv-h6-font-size: #{$h-6};

  --dv-h1-font-weight: #{$fw-medium};
  --dv-h2-font-weight: #{$fw-medium};
  --dv-h3-font-weight: #{$fw-medium};
  --dv-h4-font-weight: #{$fw-normal};
  --dv-h5-font-weight: #{$fw-normal};
  --dv-h6-font-weight: #{$fw-light};
}
