@import './src/styles/foundation/all';

section.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;


  .logo {
    margin-block: $spacing-l;
  }

  div.container {
    display: flex;
    flex-direction: column;

    border: 1px solid $primary-blue;

    border-radius: $radius-xs;

    gap: $spacing-xl;

    padding: 30px;

    .login_form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .login_input {
        width: 400px !important;
      }
    }

  }
}

@include mobile {
  section.login {

    div.container {

      padding-inline: 20px;

      .login_form {

        .login_input {
          width: 100% !important;
          max-width: 300px !important;
        }
      }
    }
  }
}
