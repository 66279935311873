/*=============================================
=            Mixins                           =
=============================================*/

/**
 *
 * Helpful reusable function-like things that will be used to implement
 * styles for components.
 *
 */

@mixin mobile {
  @media (max-width: map-get($breakpoints, "sm")) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: map-get($breakpoints, "md")) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: map-get($breakpoints, "lg")) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: map-get($breakpoints, "xl")) {
    @content;
  }
}

@mixin padding($space) {
  padding: map-get($spacing, $space);
}

@mixin margin($space) {
  margin: map-get($spacing, $space);
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin container($size) {
  max-inline-size: var($size);
  padding: 0 1rem;
  margin: 0 auto;
}
