/*=============================================
=            Foundation - colors              =
=============================================*/

/**
 * This file defines the actual colors that will be used for styling. They will default to the palette
 * we defined in the _variable.scss file. This is our default palette, and devs can override this
 * with their own variables.
 */

/*=============================================
=            Global text colors               =
=============================================*/
$body-text-color: var(--dv-body-text-color, $dark) !default;
$body-bg-color: var(--dv-body-bg-color, $white) !default;

/*=============================================
=                 Buttons                     =
=============================================*/
$btn-primary-color: var(--dv-btn-primary-color, $white) !default;
$btn-primary-bg: var(--dv-btn-primary-bg, $green) !default;
$btn-primary-bg-hover: var(--dv-btn-primary-bg-hover, $green-light) !default;

/*=============================================
=                 Forms                       =
=============================================*/
$form-border-color: var(--dv-form-border-color, $white-dark) !default;
$form-bg-color: var(--dv-form-bg-color, $white) !default;
$form-bg-option-selected: var(--dv-form-bg-option-selected, $green) !default;
$form-color-option-selected: var(
                --dv-form-color-option-selected,
                $white
) !default;
$form-bg-color-hover: var(--dv-form-bg-color-hover, $white-dark) !default;
$form-color: var(--dv-form-color, $dark) !default;
$form-bg: var(--dv-form-bg, $white) !default;
$form-error-color: var(--dv-form-error-color, $red) !default;
$form-error-border: var(--dv-form-error-border, $red) !default;
$form-border-focus-color: var(--dv-form-border-focus-color, $green) !default;


$primary-orange: var(--primary-orange, #FAA31E) !default;
$secondary-orange: var(--secondary-orange, #FEEED6) !default;

$primary-red: var(--primary-red, #E05252) !default;
$secondary-red: var(--secondary-red, #F8D9D9) !default;
$secondary-red-light: var(--secondary-red-light, #F8EDEDFF) !default;

$primary-green: var(--primary-green, #37B358) !default;
$secondary-green: var(--secondary-green, #DBF1E1) !default;
$secondary-green-light: var(--secondary-green-light, #f1fcf3) !default;

$primary-blue: var(--primary-blue, #2D6DB7) !default;
$secondary-blue: var(--secondary-blue, #F1F6FB) !default;

$primary-gray: var(--primary-gray, #5F6A70) !default;
$secondary-gray: var(--secondary-gray, #DEDEDE) !default;

$hover-color: var(--hover-color, #F0F0F0) !default;

$body-background-color: var(--body-background-color, #f7f7f7) !default;
$body-secondary-background-color: var(--body-secondary-background-color, #b7bcc0) !default;

$color-white: var(--color-white, #fff) !default;
$color-black: var(--color-black, #000) !default;
