@import './src/styles/foundation/all';

.layout {

  .content_wrapper {
    padding-top: $spacing-l;
  }
}

@include mobile {
  .layout {

    .content_wrapper {
      padding-top: $spacing-xs;
    }
  }

  .navbar {
    width: 100%;
  }
}
