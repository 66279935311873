@import './src/styles/foundation/all';

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  background-color: $primary-blue;
  padding-inline: $spacing-l;
  color: $secondary-blue;

  .left_bar {
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;

    h2 {
      margin: 0;
    }
  }

  ul.right_bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 3;
    width: 100%;
    gap: $spacing-m;
    padding: 0;
    margin: 0;
    list-style: none;

    li a {
      display: flex;
      align-items: center;
      padding-block: $spacing-s;
      padding-inline: $spacing-xxs;
      transition: .2s ease-in-out;
      color: $color-white;
    }

    li a:hover {
      background-color: $secondary-blue;
      color: $primary-blue;
      cursor: pointer;
    }
  }
}

@include mobile {
  .navbar {
    width: 100%;

    padding-inline: $spacing-s;

    .left_bar {
      h2 {
        font-size: 14px;
      }
    }

    ul.right_bar {
      li {
        font-size: 13px;
      }
    }
  }
}
