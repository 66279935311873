/* Kanit 100 - Thin */
@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 100;
  src: local(""), url("../../../src/fonts/Kanit-Thin.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url("../../../src/fonts/Kanit-Thin.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Kanit 200 - ExtraLight */
@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../../../src/fonts/Kanit-ExtraLight.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url("../../../src/fonts/Kanit-ExtraLight.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Kanit 300 - Light */
@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../../../src/fonts/Kanit-Light.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url("../../../src/fonts/Kanit-Light.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Kanit 400 - Regular */
@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../../../src/fonts/Kanit-Regular.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url("../../../src/fonts/Kanit-Regular.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Kanit 500 - Medium */
@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../../../src/fonts/Kanit-Medium.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url("../../../src/fonts/Kanit-Medium.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Kanit 600 - SemiBold */
@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../../../src/fonts/Kanit-SemiBold.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url("../../../src/fonts/Kanit-SemiBold.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Kanit 700 - Bold */
@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../../../src/fonts/Kanit-Bold.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url("../../../src/fonts/Kanit-Bold.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Kanit 800 - ExtraBold */
@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../../../src/fonts/Kanit-ExtraBold.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url("../../../src/fonts/Kanit-ExtraBold.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Kanit 900 - Black */
@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../../../src/fonts/Kanit-Black.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url("../../../src/fonts/Kanit-Black.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
