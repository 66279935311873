/*------------------------------------*\
    TABLE OF CONTENTS
\*------------------------------------*/

/**
 *
 * FOUNDATIONS................................................
 * BASE.......................................................
 */

/*=============================================
 =                   Foundations               =
 =============================================*/

@import "foundation/all";

/*==============================================
 =                   Base                      =
 =============================================*/

@import "base/reset";
@import "base/root";

@import "foundation/fonts";

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  width: 100%;
  // min-block-size: 100vw;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: $kanit;
}

// Form

.form-group {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;

  padding-top: $spacing-xs;
  padding-bottom: $spacing-xs;

  .select {
    min-width: 250px;
  }
}

.form-actions {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;

  button {
    width: 100%
  }
}
