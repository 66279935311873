@import './src/styles/foundation/all';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-s;
  padding-inline: 10px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.found_order {

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    button {
      inline-size: 100%;
    }
  }

  .deliver_button {
    color: $secondary-green;
    background-color: $primary-green;
  }

  .delivered_button {
    color: $secondary-orange;
    background-color: $primary-orange;
  }

  .decline_button {
    color: $secondary-red;
    background-color: $primary-red;
  }
}
