.w-100 {
  width: 100%;
}

// Flex Options

.d-flex {
  display: flex !important;
}

.fd-column {
  flex-direction: column;
}

.jc-center {
  justify-content: center;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end !important;
}

.ai-center {
  align-items: center;
}

// Flex Gap

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}


// Padding Options
// block
.pbs-5 {
  padding-block-start: 5px;
}

.pbs-10 {
  padding-block-start: 10px;
}

.pbs-15 {
  padding-block-start: 15px;
}

.pbs-20 {
  padding-block-start: 20px;
}

.pbe-5 {
  padding-block-end: 5px;
}

.pbe-10 {
  padding-block-end: 10px;
}

.pb-5 {
  padding-block: 5px;
}

.pb-10 {
  padding-block: 10px;
}

// inline
.pis-5 {
  padding-block-start: 5px;
}

.pis-10 {
  padding-block-start: 10px;
}

.pie-5 {
  padding-block-end: 5px;
}

.pie-10 {
  padding-block-end: 10px;
}

.pi-5 {
  padding-block: 5px;
}

.pi-10 {
  padding-block: 10px;
}

//padding

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

// Margin Options
// block
.mbs-5 {
  margin-block-start: 5px;
}

.mbs-10 {
  margin-block-start: 10px;
}

.mbe-5 {
  margin-block-end: 5px;
}

.mbe-10 {
  margin-block-end: 10px;
}

.mb-5 {
  margin-block: 5px;
}

.mb-10 {
  margin-block: 10px;
}

.mb-15 {
  margin-block: 15px;
}

// inline
.mis-5 {
  margin-block-start: 5px;
}

.mis-10 {
  margin-block-start: 10px;
}

.mie-5 {
  margin-block-end: 5px;
}

.mie-10 {
  margin-block-end: 10px;
}

.mi-5 {
  margin-block: 5px;
}

.mi-10 {
  margin-block: 10px;
}

//margin

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

//display

d-none {
  display: none;
}

// colors

.color-primary-green {
  color: #37B358;
}

.color-primary-red {
  color: #E05252;
}

.color-primary-orange {
  color: #FAA31E;
}
