@import './src/styles/foundation/all';

.dashboard {
  display: flex;
  flex-direction: column;
  gap: $spacing-l;
  padding-inline: 50px;

  .mini_cards {
    display: flex;
    flex-flow: wrap;
    gap: $spacing-s;
  }

  .stats_mobile {
    display: none;
  }

  .fa_table {
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search {
        display: flex;
        align-items: center;
        gap: $spacing-m;
      }
    }
  }

  .fa_cards {
    display: none;
  }

}

@include mobile {
  .dashboard {
    padding-inline: 10px;
    gap: $spacing-xxs;

    .mini_cards {
      display: none;
    }

    .stats_mobile {
      display: flex;
      gap: 15px;
      justify-content: flex-end;

      .stat {
        display: flex;
        align-items: center;

        .block {
          display: flex;
          flex-direction: row;
          border-right: .5px solid $color-black;
          padding-inline-start: 7.5px;
          padding-inline-end: 7.5px;
          gap: 5px;
          text-align: right;

          h4, h5 {
            margin: 0;
          }

          h4 {
            font-size: 17px;
          }

          h5 {
            font-size: 13px;
          }
        }

        .block:first-child {
          padding-inline-start: 0;
        }

        .block:last-child {
          padding-inline-end: 0;
          border-right: none;
        }
      }
    }

    .fa_table {
      display: none;
    }

    .fa_cards {
      display: flex;
      flex-direction: column;
      gap: $spacing-m;

      .card {
        display: flex;
        gap: $spacing-s;
        padding-block: $spacing-xs;
        padding-inline: $spacing-xs;
        border: .5px solid $color-black;
        border-radius: $radius-xxs;

        .side {
          display: flex;
          flex-direction: column;
          gap: $spacing-s;

          .image {
            img {
              border-radius: $radius-xxs;
            }
          }

          .actions {
            display: flex;
            flex-direction: column;
            gap: $spacing-xxs;

            button {
              font-size: 12px;
              inline-size: 100%;
              block-size: 24px;
              line-height: 0;
            }
          }
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: $spacing-xs;

          h1 {
            margin: 0;
            font-size: 16px;
          }

          h3 {
            margin: 0;
            font-size: 14px;
          }

          .description {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            font-size: 14px;

            span {
              padding-inline: 2px;
            }

          }

          i {
            color: $gray;
          }
        }
      }
    }
  }
}
